@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
%icon-large {
    display: block;
    width: 14px;
    height: 14px;
}
%icon-small {
    display: block;
    width: 12px;
    height: 12px;
}

.holidaycheck {
    display: flex;
    align-items: center;

    $this: &;

    &__recommendation {
        display: flex;
        align-items: center;
        background: #0E55CD;
        color: #fff;
        font-family: Montserrat, sans-serif;
        font-weight: bold;

        svg {
            padding-right: 5px;
            fill: #fff;
        }  
    }

    &__star {
        display: flex;
        align-items: center;
        padding: 0 8px;
        border: 1px solid #D9E1EE;
        border-left: 0;

        svg {
            margin-right: 3px;
        }
    }

    &__rating {
        &-note {
            margin-left: 3px;
            width: max-content;

            &-total {
                padding-left: 3px;
            }
        }

        &-compact {
            display: flex;
            align-items: center;
            border: 1px solid #D9E1EE;
            border-left: 0;
            border-radius: 0 $colored-product-badge-border-radius $colored-product-badge-border-radius 0;

            svg {
                margin-right: 3px;
            }
        }
    }

    &__reviews {
        margin-left: 8px;
        color: #3B4B66;
    }

    &.large {
        font-size: 1.1rem;
        #{$this}__recommendation {
            padding: 0 8px;
            height: 26px;

            svg {
                @extend %icon-large;
            }
        }
        #{$this}__star {
            height: 24px;
        }
        #{$this}__sun {
            @extend %icon-large;
        }
        #{$this}__rating {
            &-compact {
                padding: 0 8px;
                height: 24px;
            }
        }

        .icon-sun-half {
            svg {
                @extend %icon-large;
            }
        }
    }

    &.small {
        font-size: 1rem;
        #{$this}__recommendation {
            padding: 0 6px;
            height: 20px;

            svg {
                @extend %icon-small;
            }
        }
        #{$this}__star {
            height: 18px;
        }
        #{$this}__sun {
            @extend %icon-small;
        }
        #{$this}__rating {
            &-compact {
                padding: 0 6px;
                height: 18px;
            }
        }

        .icon-sun-half {
            svg {
                @extend %icon-small;
            }
        }
    }

    &.compact {
        #{$this}__rating-note-average,
        #{$this}__rating-note-total {
            font-weight: bold;
        }
        #{$this}__star {
            border-radius: 0 $colored-product-badge-border-radius $colored-product-badge-border-radius 0;
        }
        #{$this}__recommendation {
            border-radius: $colored-product-badge-border-radius 0 0 $colored-product-badge-border-radius;
        }
    }
}