@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.tripadvisor-detail {
	&__data {
		display: flex;
		flex-direction: column;
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px solid $grey-medium;
        grid-row-gap: 24px;

		@include gridle_state(md) {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-bottom: 32px;
			margin-bottom: 32px;
		}
	}

	&__location {
		@extend %text-bold;

		font-size: 2.4rem;
		line-height: 120%;
		letter-spacing: -0.24px;
		color: $black;
	}

	&__title {
		@extend %text-bold;

		margin-bottom: 24px;
		font-size: 2rem;
		line-height: 120%;
		letter-spacing: -0.2px;
		color: $black;
	}

	.tripadvisor-review {
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 1px solid $grey-medium;

		&:last-of-type {
			padding: 0;
			margin: 0;
			border-bottom: unset;
		}
	}
}
