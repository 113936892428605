@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.tripadvisor-review {
	display: flex;
	flex-direction: column;
	color: $black;
	font-size: 1.4rem;

	&__title {
		@extend %text-bold;

		margin-bottom: 10px;
		font-size: 1.6rem;
		line-height: 120%;

		@include gridle_state(md) {
			margin-bottom: 8px;
		}
	}

	&__meta {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 16px;
	}

	&__date {
		position: relative;
		display: inline-block;
		padding-right: 9px;
		margin-right: 8px;

		&::before {
			content: "";
			position: absolute;
			top: 3px;
			right: 0;
			width: 1px;
			height: 15px;
			background-color: $black;
		}
	}

	&__content {
		display: -webkit-box;
		text-overflow: ellipsis;
		word-break: break-word;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;

		&--more {
			-webkit-line-clamp: 1000;
		}
	}

	&__read-more {
		@extend %text-bold;

		display: inline-block;
		cursor: pointer;
	}
}
